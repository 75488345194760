@use '../utilities/variables';

#footer {
	background: variables.$primary;
	text-align: right;

	a {
		display: inline-block;
	}
}
