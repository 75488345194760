@use '~@jlbelanger/formosa/src/scss/utilities/variables' as formosa;
@use '../utilities/variables';

/* stylelint-disable-next-line @stylistic/max-line-length */
$chrome-height: variables.$nav-height + (variables.$content-padding * 2) + (variables.$button-size * 2) + formosa.$formosa-input-height + (variables.$tab-padding * 4) + variables.$footer-height;

#sidebar {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	margin-right: variables.$margin;
	position: relative;
	width: 370px;

	.list--scrollable {
		max-height: calc(100vh - #{$chrome-height});
		overflow: auto;
	}

	.tab-list {
		margin-top: 0;
	}

	.tab-panel {
		flex: 1 1 auto;
	}
}

.sidebar__search {
	margin-bottom: variables.$tab-padding;
	margin-top: 0;
}

.sidebar__links {
	margin-bottom: variables.$tab-padding;

	a {
		display: inline-block;
	}
}

#sidebar-resize {
	background: transparent;
	border: 0;
	bottom: 0;
	cursor: ew-resize;
	left: 100%;
	position: absolute;
	top: 0;
	width: 8px;
}
