@use 'sass:color';

/* Colors. */

$white: #fff;
$off-white: #faf9f4;
$cream: #ebe7da;
$black: #000;

$primary: #2e3b59;
$light-primary: color.scale($primary, $lightness: 50%, $saturation: -50%);
$secondary: #a9b7d9;
$light-secondary: color.scale($secondary, $lightness: 80%);
$lightest-secondary: color.scale($secondary, $lightness: 90%);

$blue: #2553c4;

$green: #608121;
$light-green: #c7d9a9;

$red: #8d3725;
$light-red: #dab2aa;

$button-background-color-hover: color.scale($primary, $lightness: 12.5%);
$button-success-background-color-hover: color.scale($green, $lightness: 10%);
$button-danger-background-color-hover: color.scale($red, $lightness: 10%);

/* Fonts. */

$serif: 'Roboto Slab', sans-serif;

/* Sizes. */

$border-radius: 4px;
$button-size: 45px;
$content-padding: 12px;
$font-size: 18px;
$footer-height: $button-size;
$nav-height: $button-size;
$margin: 40px;
$margin-small: $margin * .25;
$max-width: 1200px;
$tab-padding: 12px;
$table-padding: 12px;

/* Etc. */

$shadow: 2px 2px 12px rgba($primary, .2);
