@use '~@jlbelanger/formosa/src/scss/utilities/variables' as formosa;
@use '../utilities/variables';

$skip-width: 200px;

#skip {
	background: variables.$secondary;
	border: 0;
	border-radius: 0;
	color: variables.$black;
	font-size: 20px;
	font-weight: bold;
	height: variables.$button-size;
	left: 50%;
	line-height: variables.$button-size;
	margin-left: calc($skip-width * -.5);
	opacity: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	text-decoration: none;
	top: calc(variables.$button-size * -1);
	width: $skip-width;
	z-index: 99999;

	&:active,
	&:focus {
		box-shadow: 0 0 10px 5px rgba(variables.$black, .2), 0 0 0 formosa.$formosa-focus-border-width formosa.$formosa-focus-box-shadow-color;
		opacity: 1;
		top: 0;
	}
}
