@use '../utilities/variables';

.box {
	background: variables.$lightest-secondary;
	border-color: variables.$secondary;
	border-style: solid;
	border-width: 1px 0;
	margin-left: calc(variables.$content-padding * -1);
	margin-right: calc(variables.$content-padding * -1);
	margin-top: variables.$margin;
	padding: variables.$content-padding;

	& > :first-child {
		margin-top: 0;
	}

	@media (width > variables.$max-width) {
		border-radius: variables.$border-radius;
		border-width: 1px;
	}
}
