@use 'sass:color';
@use '~@jlbelanger/formosa/src/scss/utilities/variables' as formosa;
@use '../utilities/variables';

button {
	cursor: pointer;

	&:disabled {
		cursor: default;
	}
}

.formosa-button {
	display: inline-block;
	text-decoration: none;
}

button,
.formosa-button {
	font-family: variables.$serif;
}

.button--link {
	background-color: transparent;
	border-color: transparent;
	color: variables.$blue;
	font-family: sans-serif;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		background-color: transparent;
		text-decoration: none;
	}
}

.button--small {
	font-size: 16px;
}

.button--secondary,
.formosa-button--toggle-password {
	background: variables.$secondary;
	border-color: variables.$secondary;
	color: variables.$black;

	&:hover,
	&:active,
	&:focus {
		background: color.scale(variables.$secondary, $lightness: 10%);
		border-color: color.scale(variables.$secondary, $lightness: 10%);
	}
}

.button--outline {
	background: transparent;
	border-color: variables.$primary;
	color: variables.$primary;

	&:hover,
	&:active,
	&:focus {
		background: color.scale(variables.$primary, $lightness: 90%);
	}
}

.button--success-outline {
	background: transparent;
	border-color: variables.$green;
	color: variables.$green;

	&:hover,
	&:active,
	&:focus {
		background: color.scale(variables.$green, $lightness: 90%);
	}
}

.button--danger-outline {
	background: transparent;
	border-color: formosa.$formosa-color-danger;
	color: formosa.$formosa-color-danger;

	&:hover,
	&:active,
	&:focus {
		background: color.scale(formosa.$formosa-color-danger, $lightness: 90%);
	}
}

.formosa-postfix {
	line-height: calc(formosa.$formosa-input-height - 1px);
	padding-bottom: 0;
	padding-top: 0;
	vertical-align: top;
}
