@use '../utilities/variables';

.contain {
	margin: 0 auto;
	max-width: variables.$max-width;
	width: 100%;
}

.contain-small {
	max-width: 600px;
}
