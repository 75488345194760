@use '../utilities/variables';

.modal {
	border: 0;
	box-shadow: variables.$shadow;

	.formosa-responsive {
		margin-top: variables.$margin-small;
	}
}

.modal::backdrop {
	background: rgba(variables.$black, .5);
}

.modal__options {
	display: flex;
	gap: 4px;
	justify-content: center;
}

.modal__box > :first-child {
	margin-top: 0;
}
