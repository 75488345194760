@use '~@jlbelanger/formosa/src/scss/utilities/variables' as formosa;
@use '../utilities/variables';

.list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.list__link {
	display: block;

	&.active {
		color: variables.$light-primary;
		text-decoration: none;
	}
}

.list__link,
.list__no-results {
	margin: 0;
	padding: 12px 4px;
}

dl {
	border: 1px solid variables.$secondary;
	border-radius: variables.$border-radius;
	display: table;
	margin: 0;
	padding: 8px;
	width: 100%;

	& > div {
		display: table-row;
	}
}

dt {
	display: table-cell;
	font-family: variables.$serif;
	font-weight: bold;
	padding-right: 4px;
	vertical-align: middle;
	white-space: nowrap;
	width: 0;
}

dd {
	display: table-cell;
	height: formosa.$formosa-input-height;
	padding: formosa.$formosa-input-padding;
	vertical-align: middle;

	.formosa-field {
		margin: calc((formosa.$formosa-input-padding + formosa.$formosa-input-border-width) * -1);
	}
}
