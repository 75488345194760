@use '../utilities/variables';

table {
	border-spacing: 0;
	margin-left: calc(variables.$table-padding * -1);
	margin-right: calc(variables.$table-padding * -1);
	margin-top: variables.$margin;
	width: calc(100% + #{variables.$table-padding * 2});

	button {
		width: 100%;
	}

	.tab-panel & {
		margin-bottom: calc(variables.$tab-padding * -1);
	}
}

caption {
	font-family: variables.$serif;
	font-weight: bold;
	padding: 0 variables.$table-padding;
	text-align: left;
}

td,
th {
	border-color: variables.$secondary;
	border-style: solid;
	border-width: 0 0 1px;
	padding: 8px variables.$table-padding;

	tfoot & {
		border-width: 0;
	}

	& > .formosa-field {
		margin: 0;
	}
}

th {
	text-align: left;
}

thead {
	th {
		border-color: variables.$black;
		border-style: solid;
		border-width: 0 0 3px;
		font-family: variables.$serif;
	}
}

tbody {
	td,
	th {
		background: variables.$lightest-secondary;
	}

	th {
		font-weight: normal;
	}

	tr:nth-of-type(odd) {
		td,
		th {
			background: variables.$light-secondary;
		}
	}

	@media (width > variables.$max-width) {
		tr > :first-child {
			border-left-width: 1px;

			.tab-panel & {
				border-left-width: 0;
			}
		}

		tr > :last-child {
			border-right-width: 1px;

			.tab-panel & {
				border-right-width: 0;
			}
		}
	}
}
