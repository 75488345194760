@use '~@jlbelanger/formosa/src/scss/utilities/variables' as formosa;
@use '../utilities/variables';

* {
	box-sizing: border-box;
}

body {
	background: variables.$primary;
	color: variables.$white;
	font-family: formosa.$formosa-input-font-family;
	font-size: variables.$font-size;
	margin: 0;
}

#main {
	background: variables.$cream;
	color: variables.$black;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
