@use '../utilities/variables';

a {
	color: variables.$blue;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}
