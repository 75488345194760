@use '../utilities/variables';

#article {
	flex: 1 1 auto;
	padding: variables.$content-padding;
}

.tree {
	display: flex;
}

#article-content {
	flex: 1 1 auto;
}
