@use '../utilities/variables';

h1,
h2 {
	font-family: variables.$serif;
	margin: variables.$margin 0 0;
}

h1 {
	font-size: 32px;
}

h2,
caption {
	font-size: 24px;
}

h1 + h2,
h1 + form > :first-child,
h1 + .box,
h1 + .formosa-alert,
h1 + table,
h2 + table,
.formosa-alert + table {
	margin-top: variables.$margin-small;
}

.heading {
	align-items: center;
	display: flex;
	height: variables.$button-size;
	justify-content: space-between;
}

.heading__icon {
	display: flex;
	flex: 0 0 auto;

	svg {
		height: 36px;
		width: 36px;

		path {
			fill: variables.$light-primary;
		}
	}
}

.heading__title {
	flex: 1 1 auto;
	margin: 0 1rem 0 .5rem;
}

.heading__buttons {
	display: flex;
	gap: 4px;
}
