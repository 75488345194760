@use '../utilities/variables';

.dropdown {
	box-shadow: variables.$shadow;
	display: flex;
	flex-direction: column;
	max-width: 200px;
	position: absolute;
	right: 0;
	top: variables.$nav-height;
	width: 100%;
	z-index: 4;
}

.dropdown__button {
	border-radius: 0;
	border-width: 0;
	text-align: left;
}
