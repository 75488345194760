@use '../utilities/variables';

.formosa-alert {
	margin: variables.$margin-small 0 0;
}

.formosa-responsive {
	> :first-child {
		margin-top: 0;
	}
}

.formosa-icon--search {
	path {
		fill: variables.$secondary;
	}
}

.formosa-input-inner-wrapper {
	display: flex;
}

.formosa-field__input--select:not([size]) {
	width: auto;
}
