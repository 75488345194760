@use '../utilities/variables';

#header {
	align-items: center;
	background: variables.$primary;
	color: variables.$white;
	display: flex;
}

#header__contain {
	display: flex;
	justify-content: center;
	width: 100%;
}

.nav__button {
	border-radius: 0;
}

#title {
	align-items: center;
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
}

#title__link {
	color: variables.$white;
	font-family: variables.$serif;
	font-size: 24px;
	height: variables.$nav-height;
	line-height: variables.$nav-height;
	text-decoration: none;
}
