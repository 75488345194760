@use '../utilities/variables';

.tab-list {
	display: flex;
	margin-bottom: -1px;
	margin-top: variables.$margin-small;
	overflow: auto;
}

.tab-button {
	border: 1px solid variables.$secondary;
	border-bottom: 0;
	border-radius: variables.$border-radius variables.$border-radius 0 0;
	display: inline-block;
	margin-right: 2px;
	position: relative;
	z-index: 1;

	&[aria-selected='true'] {
		background: variables.$off-white;
		color: variables.$primary;
		z-index: 3;
	}
}

.tab-panel {
	background: variables.$off-white;
	border: 1px solid variables.$secondary;
	border-radius: 0 0 variables.$border-radius variables.$border-radius;
	padding: variables.$tab-padding;
	position: relative;
	z-index: 2;

	& > :first-child {
		margin-top: 0;
	}
}
