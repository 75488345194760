.post-alert-button {
	margin-top: 0;
	padding-top: 0;
	text-align: right;
}

.w-0 {
	width: 0;
}

.w-100 {
	width: 100%;
}

.nowrap {
	display: flex;
	gap: 4px;
}

.right {
	display: block;
	margin-left: auto;
}
